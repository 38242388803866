@font-face {
  font-family: 'SummerLovingSolid';
  src: url('../font/summerlovingsolid.woff2') format('woff2'), url('../font/summerlovingsolid.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@media screen and (min-width: 1080px) {
  .mobile-tablet-only {
    display: none !important;
  }
}

.banner--lounge .video-thumbnail {
  z-index: 15;
}

.card--nextgame a:hover {
  text-decoration: none;
  color: inherit;
}

@media (min-width: 1280px) {
  .card--offer .btn {
    visibility: visible;
    opacity: 1;
  }
}

.section--team .join-stories {
  margin-top: -28px;
  margin-bottom: 28px;
}

@media (min-width: 1080px) {
  .section--team .join-stories {
    display: flex;
    margin-top: -23px;
    margin-bottom: 28px;
  }
}

@media (min-width: 1280px) {
  .section--team .join-stories {
    margin-top: -58px;
    margin-bottom: 28px;
  }
}

.icon-twitch:before {
  content: '';
  background-image: url('../img/twitch-grey.svg');
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}

.is-dark .icon-twitch:before {
  background-image: url('../img/twitch.svg');
}
.icon-snapchat:before {
  content: '';
  background-image: url('../img/snapchat-grey.png');
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}

.is-dark .icon-snapchat:before {
  background-image: url('../img/snapchat.png');
}
.icon-tik-tok:before {
  content: '';
  background-image: url('../img/tiktok.svg');
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}

.topbar svg {
  vertical-align: middle;
}

.topbar .fid_page a {
  padding: 0;
  margin-right: 8px;
}
.topbar .nb_points {
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.06);
  margin-left: 8px;
  padding-left: 16px;
}
.topbar .nb_points a {
  padding: 0;
}
.topbar .nb_points span {
  color: #fff;
  position: absolute;
  top: 7px;
  line-height: initial;
  width: 100%;
  text-align: center;
  display: block;
  margin-left: -8px;
}

.dashboard-menu__profil {
  height: auto;
  font-weight: bold;
}

.message_bienvenue {
  font-family: 'SummerLovingSolid';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 0;
}

.fid_statut {
  position: absolute;
  transform: rotate(-4deg) translateX(-50%);
  left: 50%;
  top: 128px;
  background-color: #002348;
  border-radius: 4px;
  padding: 4px 8px;
  color: #fff;
  text-transform: uppercase;
}
.degrade_fid {
  background: linear-gradient(83.77deg, #164192 -4.24%, #a51a59 48.83%, #e21f20 101.9%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  line-height: 1;
  display: inline-block;
}

.dashboard-menu__profil > div {
  background: linear-gradient(83.77deg, #164192 -4.24%, #a51a59 48.83%, #e21f20 101.9%);
  padding: 4px;
  border-width: 0px;
}

.dashboard-menu__profil > div img {
  background: #fff;
}

@media (max-width: 1079px) {
  .header__wrapper .menu > a > span {
    display: none !important;
  }
}

.header__wrapper .menu > a.btn {
  padding: 0 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.home-slider__wrapper h2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu li.topbar__current_competition__mobile a span {
  color: #b60718;
}

@media (min-width: 1080px) and (max-width: 1245px) {
  .topbar__current_competition a::before {
    font-family: 'icon';
    content: '\e934';
    line-height: 14px;
  }
  .topbar__current_competition a span {
    display: none;
  }
}

.topbar-competition-alert {
  display: flex;
  width: 100vw;
  padding: 4px 20px 4px 0;
  margin-left: -8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: var(--red-dark, #b60718);
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}

@media screen and (min-width: 1280px) {
  .topbar-competition-alert {
    width: unset;
    padding-left: 20px;
  }
}

.topbar-competition-alert .marquee__content:after {
  content: '•';
  margin-left: 10px;
  margin-right: 10px;
}

.topbar-competition-alert .btn--white {
  color: var(--red-dark, #b60718);
  border-radius: 35px;
  line-height: 2;
  height: inherit;
  font-size: 12px;
}

.topbar-competition-alert .btn--white:hover,
.topbar-competition-alert .btn--white:focus {
  color: var(--red-dark, #b60718);
}

.topbar-competition-alert .separateur {
  opacity: 0.5;
  margin: 0 1em;
  display: inline-block;
}

@media (min-width: 1080px) {
  header.has-current-competition + .home-slider {
    height: 840px;
  }

  header.has-current-competition + .home-slider + .card--news-direct-container {
    top: 195px;
  }

  header.has-current-competition + .banner:not(.banner--big):not(.banner--searchClub) {
    padding-top: 210px;
    height: 488px;
  }

  header.has-current-competition + .banner--big,
  header.has-current-competition + .banner--searchClub {
    padding-top: 210px;
  }
  header.has-current-competition + .banner--searchClub {
    height: 885px;
  }

  header.has-current-competition + .banner--big {
    margin-bottom: -300px;
  }
}
@media (max-width: 1080px) {
  .topbar-competition-alert {
    margin: 0;
    padding: 8px;
  }
  .topbar-competition-alert .separateur {
    margin: 0 0.5em;
  }

  .topbar-competition-alert .btn--white {
    padding: 0 12px;
  }
  .topbar .topbar-competition-alert {
    display: none;
  }
}

@media (min-width: 1080px) {
  .header__mobile + .topbar-competition-alert {
    display: none;
  }
}

.btn--x,
.btn--twitter {
  background-color: #000;
}

.icon-twitter:before {
  content: '';
  background-image: url('../img/x_twitter-grey.svg');
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}

.is-dark .icon-twitter:before {
  background-image: url('../img/x_twitter.svg');
}

.icon-discord:before {
  content: '';
  background-image: url('../img/discord-icon.svg');
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}

.icon-discord-grey:before {
  background-image: url('../img/discord-icon-grey.svg');
}

.link-icon.link-icon--small {
  line-height: 24px;
}

.SocialMediaShareButton .icon-twitter:before {
  width: 16px;
  height: 16px;
}

@media (min-width: 1280px) {
  .match__club img {
    width: unset;
    /* height: unset; */
  }
}

.display-desktop-only {
  display: none !important;
}
@media screen and (min-width: 1280px) {
  .display-desktop-only {
    display: block !important;
  }
}

.display-inline-block-desktop-only {
  display: none !important;
}
@media screen and (min-width: 1280px) {
  .display-inline-block-desktop-only {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1280px) {
  .display-til-tablet {
    display: none !important;
  }
}

@media screen and (max-width: 1079px) {
  .display-small-screen-only {
    display: none !important;
  }
}

.display-from-medium-screen {
  display: none !important;
}

@media (min-width: 1079px) {
  .display-from-medium-screen {
    display: block !important;
  }
}

.display-none {
  display: none;
}

.section.section--join-stories {
  padding-top: unset;
}

.block-infos-generales__body .update-email {
  margin-right: 16px;
}

.link-club-alt {
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .footer__corporate-links li:last-child {
    margin: unset;
  }
}

@media (min-width: 1280px) {
  .article .match-data-for-post .team-composition {
    margin-left: -32px;
  }
}

@media (max-width: 1079px) {
  .menu__club {
    position: unset;
    width: 100%;
    border-radius: 4px;
  }
}

.menu__sub .trouver-un-club {
  color: #2997f9;
}

.menu__sub .trouver-un-club::before {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-family: 'icon';
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-style: normal;
  margin-top: -1px;
  margin-right: 8px;
  content: '\e917';
}

.news--search .breadcrumb.label--small {
  display: inline-block;
  background-color: #f2362f;
  color: #fff;
  border-radius: 2px;
}

.news--search .breadcrumb.label--small li a:hover {
  color: #ccc;
}

.news--search .breadcrumb.label--small li:last-child {
  color: #fff;
}

.topbar-competition-alert .rfm-marquee-container {
  width: 80%;
}

.billboard-header .news-section {
  color: #fff;
}

@media screen and (min-width: 1080px) {
  .billboard-header {
    display: flex;
    height: 614px;
  }

  .billboard-header .news-section {
    height: 614px;
    flex-grow: 1;
  }

  .billboard-header .card--news-direct {
    width: 386px;
    height: 614px;
    background-color: #013974;
  }

  .billboard-header .card--news-direct .card__head {
    color: #fff;
  }

  .billboard-header .card--news-direct .box {
    background-color: #013974;
    color: #fff;
  }
}

.news-section_button-wrapper {
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
}

.news-section_button-wrapper a {
  padding: 16px;
}

.news-section_button-wrapper a:hover {
  color: #fff;
}

.news-section_button-wrapper a:first-child {
  border: 1px solid #fff;
  border-radius: 24px;
}

.news-section_button-wrapper a i.icon {
  margin-right: 12px;
}

@media screen and (min-width: 1080px) {
  .news-section h2 {
    font-size: 48px;
    line-height: 1.2em;
  }
  .news-section_button-wrapper {
    flex-direction: row;
  }
}

.menu__club:after {
  content: '';
  position: absolute;
  right: -11px;
  top: 8px;
  display: block;
  width: 2px;
  height: 32px;
  background-color: unset;
}

.img-blue-gradient:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  will-change: opacity;
}

.img-blue-gradient:after {
  opacity: 0.6;
  background: linear-gradient(to top, #002348 0%, #002348 100%);
}
.hide-minScreen-inline {
  display: inline-block !important;
}
@media screen and (min-width: 1280px) {
  .hide-minScreen-inline {
    display: none !important;
  }
}

.display-desktop-inline {
  display: none !important;
}
@media screen and (min-width: 1280px) {
  .display-desktop-inline {
    display: inline-block !important;
  }
}

.footer__corporate-links li div.accept-cookie-container {
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .subrouter-links.navbar__links a:hover,
  .subrouter-links.navbar__links a:focus,
  .subrouter-links.navbar__links a.is-active {
    color: unset;
    text-decoration: none;
  }

  .subrouter-links {
    margin: 0 auto;
  }

  .subrouter-links > li {
    height: 40px; /* 60px; */
    text-align: center;
    line-height: unset;
    display: table-cell;
    vertical-align: middle;
  }

  .subrouter-links > li:not(.js-menuPlus) {
    width: 240px;
    border-right: 1px solid #ccc;
  }

  .subrouter-links > li.js-menuPlus {
    border-bottom: 4px solid #fff;
  }
  
  .subrouter-links > li:first-child() {
    border-left: 1px solid #ccc;
  }

  .subrouter-links > li a {
    width: 240px;
    display: table-cell;
    line-height: 100%;
    vertical-align: middle;
  }

  .subrouter-links > li a.is-active::after,
  .subrouter-links > li a:hover,
  .navbar__links > li:not(.navbar__plus) > a:hover::after {
    height: unset !important;
    border-bottom: unset !important;
  }

  .subrouter-links > li a {
    line-height: 32px;
  }

  .subrouter-links > li:not(.js-menuPlus) {
    border-bottom: 4px solid #ccc;
  }
  .subrouter-links > li.js-menuPlus {
    border-bottom: 4px solid #fff;
  }

  .subrouter-links > li.is-active,
  .subrouter-links > li:hover {
    border-bottom: 4px solid #2a9bff;
  }
}

.icon-arrow-right.translate {
  margin-left: 8px;
  transform: rotate(315deg);
}

.card--offer .card--offer__description {
  color: #999;
  margin-bottom: 16px;
}

.cta-with-image .section-container {
  padding: 50px;
}

.cta-with-image .image-container {
  display: none;
}

.cta-with-image .cta-with-image__label {
  display: block;
  font-size: 20px;
  color: #e52020;
  margin: 22px 0;
}

.cta-with-image .cta-with-image__description {
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 22px;
}

.cta-with-image .btn--competitions-internationales {
  border-radius: 40px;
  background: var(--red-dark, #b60718);
  color: var(--greyscale-true-white, #fff);
  font-family: 'Din';
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding: 18px 24px;
  text-transform: uppercase;
  margin-top: 48px;
  margin-bottom: 24px;
}

.btn--competitions-internationales.is-small {
  font-size: 12px;
  display: block;
  width: fit-content;
}

.btn--competitions-internationales.no-decoration {
  text-decoration: none;
}

@media (min-width: 1080px) {
  .cta-with-image {
    display: flex;
  }
  .cta-with-image .section-container {
    width: 50%;
  }

  .cta-with-image .image-container {
    display: block;
    width: 50%;
  }

  .cta-with-image .image-container div {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media (max-width: 1079px) {
  .ffr-tv__theme-selector .home-slider__news-categories .mobile-tablet-only {
    display: flex !important;
    overflow-x: scroll;
  }
}

@media (max-width: 1279px) {
  .ffr-tv__theme-selector .home-slider__news-categories {
    height: fit-content;
  }

  .ffr-tv__theme-selector li {
    height: 40px;
    width: fit-content;
    white-space: nowrap;
  }
}

.display-desktop-only {
  display: none !important;
}

@media (min-width: 1080px) {
  .display-desktop-only {
    display: block !important;
  }

  .mobile-tablet-only {
    display: none !important;
  }
}

/* C3PR et nouvelles topbar */
.btn--red-dark {
  background-color: #f2362f;
}

.topbar .btn--red-dark {
  line-height: 32px;
  height: unset;
  display: block;
  float: right;
  font-size: 13px;
  font-weight: 500;
  margin-right: 16px;
  margin-top: 4px;
}

body {
  /** Fix pour que la nav puisse être sticky */
  overflow:visible!important; 
}
.topbar {
  position:sticky !important;
  top:0;
  z-index:11!important;
}

@media (max-width: 1079px) {
  body {
    /** Fix pour que la nav puisse être sticky */
    overflow:visible!important; 
  }
  #root > .topbar:not(#topbar--mobile) {
      display: none;
  }

  .dropdown__wrapper {
    overflow-x:hidden;
  }
  
  .dropdown .dropdown__list {
    max-width:100%;
  }
  
  .card--news-direct .dropdown .dropdown__head {
    padding-right:16px;
  }

  #topbar--mobile {
    color: white;
    height: 36px;
    text-align: center;
    position:sticky !important;
    top:0;
    z-index:11!important;
  }

  #topbar--mobile.topbar {
    padding: unset;
    background-color: unset;
    margin-bottom: unset;
    line-height: 32px;
  }

  #topbar--mobile .topbar {
    z-index:11!important;
  }

  #topbar--mobile.topbar li {
    margin-bottom: unset;
  }

  #topbar--mobile .topbar--mobile__left {
    background-color: #002348;
    flex-grow: 1;
  }

  .icon-chevron-down::after {
    content: '\e928';
    font-family: 'icon';
    font-size: 6px;
    margin-left: 6px;
    display: block;
  }

  #topbar--mobile .topbar--mobile__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #topbar--mobile .topbar--mobile__left .topbar--mobile__links {
    text-align: left;
  }

  #topbar--mobile.topbar .topbar__right {
    width: 40%;
    max-width: 180px;
  }

  #topbar--mobile > ul:nth-child(1) {
    display: flex;
    overflow:hidden;
  }

  .topbar--mobile__button {
    width: 100%;
    color: white;
  }

  .topbar--mobile__links {
    display: none;
  }

  .topbar--mobile__links.is-visible {
    display: block;
    position: absolute;
  }

  .topbar--mobile__links.is-visible li {
    line-height: 42px;
  }

  .topbar--mobile_important-link {
    background-color: #f2362f;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 4px;
    width: 30%;
  }

  .topbar--mobile_important-link a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #topbar--mobile .topbar__right {
    background-color: #7d93a8;
    display: block;
  }

  #topbar--mobile .topbar__login {
    margin-top: unset;
    margin-right: unset;
    background-color: #7d93a8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .topbar__right .topbar__sub {
    width: 230px;
    position: absolute;
    right: 0;
    background-color: #7d93a8;
    line-height: 2;
    padding: 8px;
    color: rgba(255, 255, 255, 0.6);
  }
  .topbar__right .topbar__sub li {
    display: block;
  }
  .topbar__right .topbar__sub a {
    padding: 0;
  }
  .topbar__right i {
    top: -2px;
  }
  .topbar__right .topbar__sub {
    padding-bottom: 16px;
  }

  .topbar--mobile__left .accordion-trigger,
  .topbar--mobile__left .accordion-trigger.is-open {
    color: #7d93a8 !important;
  }

  .topbar__user {
    padding: 0 8px;
    color: #fff;
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-left: 1px solid rgba(255, 255, 255, 0.06);
  }
  .topbar__user img {
    margin-right: 16px;
    border-radius: 100%;
  }
  .topbar__login {
    display: inline-block;
    padding: 0;
    background: #1a395a;
    color: #fff;
    padding: 0 8px;
    border-radius: 4px;
    margin-right: 4px;
    margin-top: 4px;
  }
  .topbar__login:hover {
    background: #20456e;
  }

  .header__mobile {
    line-height: unset;
    text-align: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }

  .header__mobile > i {
    position: relative;
    right: unset;
  }

  .header__mobile div .icon.icon-menu {
    width: 72px;
    line-height: 72px;
    font-size: 20px;
    color: #7d93a8;
    cursor: pointer;
  }

  .header--transparent .header__mobile div .icon.icon-menu {
    color: white;
  }

  .header__mobile .input--search.input--expand {
    color: #7d93a8;
    position: relative;
    height: fit-content;
  }

  .header__mobile .input--search.input--expand.is-open {
    position: absolute;
    right: 0;
    height: 72px;
    top: 0;
  }

  .header__mobile__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header__mobile .icon.icon-place {
    display: inline-block;
    color: #7d93a8;
    font-size: 26px;
    margin-right: unset;
  }

  .header--transparent .header__mobile .icon.icon-place {
    color: white;
  }

  .menu__right {
    display: none;
  }

  .header__wrapper .topbar {
    display: none;
  }

  .header__wrapper .menu {
    padding-top: unset;
  }

  #topbar--mobile .accordion-trigger {
    padding-right: 22px;
    position: relative;
  }

  .topbar--mobile__left.is-open .icon-chevron-down::after,
  #topbar--mobile .accordion-trigger.is-open::after {
    content: '\e929';
  }

  #topbar--mobile .accordion-trigger:after {
    float: none;
    position: absolute;
    right: 8px;
    top: 0;
    line-height: unset;
  }
  .banner {
    min-height: 466px;
    padding-top: 122px;
  }

  /* .header.has-current-competition + section.banner {
    min-height: 532px; + 66px pour la bannière de compétitions
    padding-top: 188px;
  } */
  .banner.banner--big,
  .banner.banner--bigNoAfter {
    height: 580px;
  }
}

@media (max-width: 767px) {
  .header__wrapper.is-visible {
    top: 108px;
    height: calc(100% - 108px);
  }
}
